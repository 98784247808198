import * as React from "react"
import { Link } from "gatsby";
import './404.scss';

// markup
const NotFoundPage = () => {
  return (
    <main>
      <title>Not found</title>
      <div className="page_not_found">
        <img src="https://hr-sense-public-assets.s3.ap-south-1.amazonaws.com/website/page_not_found.png" alt=""/>
        <Link to="/">Go Home</Link>.
      </div>
    </main>
  )
}

export default NotFoundPage
